.subscription-modal-container {
  background-color: rgba(#070707, 0.5);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  .subscription-modal {
    background: linear-gradient(
      180deg,
      rgba(59, 64, 71, 1),
      rgba(32, 35, 38, 1)
    );

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 460px;
    .title {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
    }
    .form-section {
      width: 100%;

      .outlined-input {
        padding: 16px 15px;
        border: 1.5px solid rgba(255, 255, 255, 0.47);
        border-radius: 20px;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        background: transparent;
        color: rgba(255, 255, 255, 1);
        outline: none;
      }
      .country-drop-down {
        .down-arrow {
          cursor: pointer;
        }
        .zip-code {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .phone-field {
        flex: 1;
      }
    }
    .policy {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: rgba(127, 132, 137, 1);
      opacity: 0.6;
    }
    .confirm-btn {
      background: linear-gradient(
        180deg,
        rgba(255, 103, 107, 1),
        rgba(238, 64, 68, 1)
      );
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      padding: 15px 40px;
      color: #ffffff;
      cursor: pointer;
      border-radius: 20px;
    }
    .cancel-btn {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      padding: 15px 40px;
      border: 1.4px rgba(255, 255, 255, 1) solid;
      color: #ffffff;
      cursor: pointer;
      border-radius: 20px;
    }
  }
}
