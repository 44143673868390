.profile-page {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  background-color: black;
  &.background-blur {
    filter: blur(12px);
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    overflow: hidden;
    filter: blur(100px);
    z-index: 1;
  }
  &::after {
    top: 0;
    left: 0;
    width: 40%;
    height: 45%;
    border-radius: 100% 0% 100% 0% / 0% 0% 100% 100%;
    background: radial-gradient(circle at 0% 30%, rgba(#fe5858, 0.5), black);
  }
  &::before {
    bottom: 0;
    right: 0;
    width: 40%;
    height: 45%;
    border-radius: 100% 0% 100% 0% / 100% 100% 0% 0%;
    background: radial-gradient(circle at 50% 30%, black, rgba(#fe5858, 0.5));
  }
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .container {
    @media (max-width: 576px) {
      padding: 0 !important;
    }
  }

  .navr-bar {
    position: relative;
    z-index: 2;
    @media (max-width: 576px) {
      padding: 0 1rem 1rem;
    }
    .live-status {
      display: none;
      @media (max-width: 576px) {
        display: block;
        position: relative;
        font-weight: 400;
        font-size: 24px;
        color: #ce2e33;
        .dot {
          position: absolute;
          top: 55%;
          left: -30%;
          transform: translateY(-50%);
          border-radius: 50%;
          width: 16px;
          height: 16px;
          background-color: #ce2e33;
        }
      }
    }
    .store-badge {
      @media (max-width: 576px) {
        display: none;
      }
    }
    .search-form-section {
      background: #000000;
      border: 1px solid #fe5858;
      border-radius: 6px;
      width: 58%;
      @media (max-width: 576px) {
        display: none !important;
      }

      .search-input {
        width: 90%;
        font-weight: 300;
        font-size: 16px;
        color: #ffffff;
        background: transparent;
        outline: none;
        border: none;
      }
      .submit-btn {
        background: transparent;
        border: none;
        outline: none;
        @media (max-width: 576px) {
          background: #000000;
          padding: 1rem;
          border: 1px solid #707070;
          border-left: none;
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }

  .container-card {
    position: relative;
    margin: auto 90px;
    z-index: 2;
    &.container-border {
      background: #000000;
      border: 1px solid #fe5858;
      border-radius: 12px;
      @media (max-width: 576px) {
        border: none;
        border-radius: 0;
      }
    }
    @media (max-width: 1024px) {
      margin: auto 50px;
    }
    @media (max-width: 768px) {
      margin: auto 0px;
    }
    @media (max-width: 576px) {
      flex-direction: column;
    }

    .profile-section {
      position: relative;
      z-index: 2;
      @media (max-width: 576px) {
        padding: 0 0.5rem;
      }
      .profile-pic {
        position: relative;
        img {
          text-indent: -10000px;
        }
        .spinner-border {
          position: absolute;
          bottom: 0%;
          left: 10%;
          @media (max-width: 576px) {
            transform: translate(170px, -20px);
          }
        }
        .sound-icon {
          position: absolute;
          bottom: -10%;
          left: -10%;
          @media (max-width: 576px) {
            transform: translate(170px, -20px);
          }
          &.mute-btn,
          &.unmute-btn {
            background: transparent;
            border: none;
            outline: none;
            @media (max-width: 576px) {
              img {
                width: 75px;
                height: 75px;
              }
            }
          }
        }
      }
      .name {
        font-weight: 400;
        font-size: 36px;
        color: #ffffff;
        text-transform: capitalize;
        @media (max-width: 1200px) {
          font-size: 40px;
        }
        @media (max-width: 576px) {
          font-size: 24px;
          margin-bottom: 0 !important;
        }
      }

      .live-status {
        position: relative;
        font-weight: 400;
        font-size: 24px;
        @media (max-width: 576px) {
          display: none;
        }

        &.on-air {
          color: #ce2e33;
          .dot {
            background-color: #ce2e33;
          }
        }
        &.off-air {
          color: #a4a4a4;
          .dot {
            background-color: #a4a4a4;
          }
        }
        .dot {
          position: absolute;
          top: 55%;
          left: -30%;
          transform: translateY(-50%);
          border-radius: 50%;
          width: 16px;
          height: 16px;
        }
      }

      .btn {
        padding: 5px 20px;
        background-color: #ce2e33;
        border-radius: 9px;
        color: #ffffff;
        cursor: pointer;
      }

      .about-card {
        width: 220px;
        background: #090000;
        border: 1.5px solid #ce2e33;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        @media (max-width: 576px) {
          display: none;
        }
        .title {
          font-weight: 400;
          font-size: 18px;
          text-align: center;
          color: #ffffff;
        }
        .description {
          font-weight: 300;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          color: #ffffff;
        }
      }
    }

    .content-section {
      width: 70%;
      @media (max-width: 576px) {
        width: 100%;
      }
      .location {
        background: #000000;
        border: 1.5px solid #ed5054;
        border-radius: 6px;
        font-weight: 300;
        font-size: 12px;
        color: #ffffff;
        @media (max-width: 1200px) {
          font-size: 28px;
        }
        @media (max-width: 576px) {
          font-size: 14px;
          margin-bottom: 0 !important;
          margin-right: 1rem;
        }
      }
      .duration {
        font-weight: 400;
        font-size: 20px;
        color: #ed5054;
        @media (max-width: 576px) {
          padding: 1rem;
        }
      }

      .comment-container {
        height: 340px;
        overflow-y: hidden;
        background: #000000;
        border: 1px solid #fe5858;
        border-radius: 8px;
        @media (max-width: 576px) {
          height: 320px;
          border: none;
          border-bottom: 0.5px solid #5a5a5a;
          border-radius: 0;
          padding: 0 1rem;
        }
        .heading {
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
        }
        .comments-section {
          max-height: 90%;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 0;
            background: transparent;
          }
        }
      }

      .comment-form-section {
        background: #000000;
        border: 1px solid #fe5858;
        border-radius: 6px;
        @media (max-width: 576px) {
          border: none;
          border-radius: 0;
          background: #232323;
        }

        .comment-input {
          width: 90%;
          font-weight: 300;
          font-size: 16px;
          color: #ffffff;
          background: transparent;
          outline: none;
          border: none;
          @media (max-width: 576px) {
            background: #000000;
            padding: 1rem;
            border: 1px solid #707070;
            border-right: none;
            border-radius: 6px 0 0 6px;
          }
        }
        .submit-btn {
          background: transparent;
          border: none;
          outline: none;
          @media (max-width: 576px) {
            background: #000000;
            padding: 1rem;
            border: 1px solid #707070;
            border-left: none;
            border-radius: 0 6px 6px 0;
          }
        }
      }
    }
    .store-badge-mobile {
      background: black;
      padding: 1rem 0 0;
      @media (min-width: 577px) {
        display: none !important;
      }
    }
  }
}

.background-gradient {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 38%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(1, 1, 1, 0));
  }
  &::after {
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 48%;
    background: linear-gradient(
      to bottom,
      rgba(1, 1, 1, 0),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 1)
    );
  }
}

.transcription {
  min-height: 130px;
  max-height: 130px;
  height: 130px;
  overflow-y: scroll;
  text-align: justify;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  color: #ffffff;
}
