@import "bootstrap/scss/bootstrap";
// @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Inter:400,500");

body {
  background: #000;
}

.avatar {
  .avatar-pic {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #ed5054;
    @media (max-width: 576px) {
      width: 120px;
      height: 120px;
    }
  }
}

.white-placeholder::placeholder {
  color: #ffffff;
}
