.modal-backdrop {
  background-color: rgba(#070707, 0.7);

}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

@media (min-width: 576px) {
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
}

.modal-dialog {
    margin: 0.5rem;
    max-width: calc(100% - 1rem);
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
}

.modal-content {
    max-height: calc(100vh - 1rem);
    padding-bottom: 1rem;
}

@media (min-width: 576px) {
    .modal-content {
        max-height: calc(100vh - 3.5rem);
    }
}

.custom-modal {

    background: linear-gradient(180deg,
            rgba(59, 64, 71, 1),
            rgba(32, 35, 38, 1));

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}